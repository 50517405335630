/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O chalupě"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-cgazry pb--60 pt--80" name={"uvod-1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/25609/c9527e47614140878d8d6d4a21df2a1c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/c9527e47614140878d8d6d4a21df2a1c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/25609/c9527e47614140878d8d6d4a21df2a1c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/c9527e47614140878d8d6d4a21df2a1c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/25609/c9527e47614140878d8d6d4a21df2a1c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/25609/c9527e47614140878d8d6d4a21df2a1c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/c9527e47614140878d8d6d4a21df2a1c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/c9527e47614140878d8d6d4a21df2a1c_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 pb--10 pt--10 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">O chalupě</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" name={"ufdypsxbkp"} style={{"paddingBottom":124}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box text-box--justify fs--16" content={"Chalupa a související pozemky zahrady jsou kompletně oplocené. Po průjezdu dálkově ovládanou bránou a po zaparkování auta na privátním, prostorném parkovišti (až 4 osobní vozidla), projdete po dlážděné cestě přední zahradou s&nbsp;ovocnými a okrasnými stromy a dominantním triem borovic. Krytý vchod do chalupy nabízí uložení kol, lyží s&nbsp;možností uzamčení."}>
              </Text>

              <Text className="text-box text-box--justify fs--16" style={{"marginTop":32}} content={"Za dveřmi naproti hlavnímu vchodu se nachází krátká chodba ústící do velkoryse pojatého společného prostoru kuchyně, jídelny a obývacího pokoje. Moderně a zcela kompletně vybavená kuchyně nabízí možnost přípravy veškerých pokrmů studené i teplé kuchyně (prostorná lednice, vestavěná mikrovlnná trouba, horkovzdušná trouba, varná deska, profesionální kávovar, varná konvice, tyčový mixér, několik kompletních sad příborů a kuchařského náčiní a multifunkční hrnec, umožňující jednoduše vařit polévky, omáčky, maso nebo rýži). Jídelní stůl nabízí místo k&nbsp;sezení až pro 10 osob. Volně k&nbsp;dispozici jsou dvě jídelní židličky pro děti. V obývacím pokoji s&nbsp;rozměrnou televizí a krbem (dřevo do krbu je hostům k&nbsp;dispozici v&nbsp;rámci ceny pobytu) se lze usadit na dvou pohovkách. Televize nabízí nespočet programů internetové televize; wifi je dostupná v&nbsp;plné rychlosti po&nbsp;celém objektu chalupy. \n       \n<br>"}>
              </Text>

              <Text className="text-box text-box--justify fs--16" style={{"marginTop":32}} content={"V&nbsp;přízemí chalupy se nachází i samostatné WC a plnohodnotná koupelna s prostorným sprchovým koutem.\n \n       \n<br>"}>
              </Text>

              <Text className="text-box text-box--justify fs--16" style={{"marginTop":32}} content={"Místnost pod schody byla vyloučena z&nbsp;volného použití a je uzamčena.\n\n \n       \n<br>"}>
              </Text>

              <Text className="text-box text-box--justify fs--16" style={{"marginTop":32}} content={"Ze společného prostoru vedou portálové dveře na východní krytou terasu s&nbsp;posezením. Z&nbsp;terasy se po pár schodech dostanete na uklidňující zadní zahradu s&nbsp;ohništěm, ovocnými stromy a keři. Sezonní ovoce je k dispozici hostům k&nbsp;volnému sběru. \n\n\n \n       \n<br>"}>
              </Text>

              <Text className="text-box text-box--justify fs--16" style={{"marginTop":32}} content={"Schody, vedoucí do nadzemního patra, jsou vybaveny permanentním nočním osvětlením, pro větší bezpečnost. Přesto Vás nabádáme ke zvýšení opatrnosti v&nbsp;rámci pohybu po schodech. \n \n\n\n \n       \n<br>"}>
              </Text>

              <Text className="text-box text-box--justify fs--16" style={{"marginTop":32}} content={"V&nbsp;horním patře se nachází další samostatné WC a koupelna vybavená vanou se sprchou.\n \n \n\n\n \n       \n<br>"}>
              </Text>

              <Text className="text-box text-box--justify fs--16" style={{"marginTop":32}} content={"Vlevo, za prvními dveřmi od schodů, se nachází ložnice (B). Je vybavena velice pohodlnou manželskou postelí s&nbsp;možností plnohodnotné přistýlky, nebo dětské postýlky. Úložné prostory a věšák na oblečení jsou samozřejmostí.  Stejnou dispozicí disponuje i ložnice (C), nacházející se za druhými dveřmi nalevo od schodů.    \n\n \n \n\n\n \n       \n<br>"}>
              </Text>

              <Text className="text-box text-box--justify fs--16" style={{"marginTop":32}} content={"Napravo od schodů se nachází největší ložnice (D), která je vybavena jednou manželskou postelí s&nbsp;nočními stolky a osvětlením a dvěma jednolůžkovými postelemi. Centrem ložnice je knihovna vybavená&nbsp;knihami náročnější i odpočinkové literatury k&nbsp;volnému čtení pro dospělé a knihovnička pro děti s&nbsp;hračkami.    \n    \n\n \n \n\n\n \n       \n<br>"}>
              </Text>

              <Text className="text-box text-box--justify fs--16" style={{"marginTop":32}} content={"V&nbsp;chalupě je k&nbsp;dispozici plnohodnotná, přenosná přistýlka pro dospělého a další pro dítě do 3 let.  \n    \n    \n\n \n \n\n\n \n       \n<br>"}>
              </Text>

              <Text className="text-box text-box--justify fs--16" style={{"marginTop":32}} content={"Všechny postele jsou čistě povlečené a upravené. Ke každé posteli jsou navíc polštáře pro větší pohodlí. Pro nejmenší máme taky peřinku a polštář s povlečením a matrací do přenosné postýlky.\n  \n    \n    \n\n \n \n\n\n \n       \n<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--25" name={"paticka"} style={{"backgroundColor":"rgba(21,21,22,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Subtitle className="subtitle-box fs--20 w--600" content={"<span style=\"color: var(--color-custom-2);\">Chalupavojtechov.cz</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">Vojtěchov č. ev. 107<br>593 01 Lísek<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20 lh--16" content={"<br><span style=\"color: rgb(201, 201, 201);\">+420</span> <span style=\"color: var(--color-custom-2);\">774 344 014</span><br><a href=\"mailto:nikola@chalupavojtechov.cz?subject=Zpr%C3%A1va+z+webu\"><span style=\"color: rgb(201, 201, 201);\">nikola</span><span style=\"color: var(--color-custom-2);\">@chalupavojtechov.cz</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}